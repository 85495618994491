import React from "react"
import { Box } from "theme-ui"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import RichContentTextBlock from "./richContentTextBlock"

const IntroBlock = ({ text }) => (
  <Wrapper>
    <Box
      sx={{
        paddingLeft: [4, 5, 5, "4rem"],
        borderLeft: "6px solid",
        borderColor: "primary",
        "& > *": {
          marginBottom: "2rem",
          "&:last-child": {
            marginBottom: 0,
          },
        },
      }}
    >
      <RichContentTextBlock html={text} />
    </Box>
  </Wrapper>
)

const Wrapper = styled.div``

export default IntroBlock
